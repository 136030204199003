<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('lead.assignedAgentDetail')"
    class="px-5 py-3"
    v-if="detail"
  >
    <assigned-agent-detail-content
      :assignedAgent="detail.assigned_agent"
    ></assigned-agent-detail-content>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const AssignedAgentDetailContent = () =>
  import('@/components/transaction-management/lead/partials/assigned-agent-detail-content');

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  components: {
    AssignedAgentDetailContent,
  },
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
    }),
  },
  methods: {
    // async acceptAction(id) {
    //   console.log('ACCEPT AGENT VERIFICATION ID: ', id);
    //   try {
    //     await this.$store.dispatch('agentVerification/accept', id);
    //     await this.$swal(
    //       this.$i18n.t('general.success'),
    //       this.$i18n.t('agentVerification.accept.successMsg'),
    //       'success',
    //     );
    //     this.$router.replace('/registration-management/agent-verifications');
    //     // eslint-disable-next-line no-empty
    //   } catch (e) {}
    // },
    // async rejectAction({ id: id, reason: reason }) {
    //   console.log('REJECT AGENT VERIFICATION ID: ', id);
    //   try {
    //     await this.$store.dispatch('agentVerification/reject', { id: id, reason: reason });
    //     await this.$swal(
    //       this.$i18n.t('general.success'),
    //       this.$i18n.t('agentVerification.reject.successMsg'),
    //       'success',
    //     );
    //     this.$router.replace('/registration-management/agent-verifications');
    //     // eslint-disable-next-line no-empty
    //   } catch (e) {}
    // },
    // async pendingAction(id) {
    //   console.log('PENDING AGENT VERIFICATION ID: ', id);
    //   try {
    //     await this.$store.dispatch('agentVerification/pending', id);
    //     await this.$swal(
    //       this.$i18n.t('general.success'),
    //       this.$i18n.t('agentVerification.pending.successMsg'),
    //       'success',
    //     );
    //     this.$router.replace('/registration-management/agent-verifications');
    //     // eslint-disable-next-line no-empty
    //   } catch (e) {}
    // },
  },
};
</script>
